import {DateTimePicker} from "@material-ui/pickers";

export interface DateTimeEditorProps {
    value: number;
    onChange: (newValue: number) => void;
}

export default function DateTimeEditor(props: DateTimeEditorProps) {
    // TODO: style text white.
    const handleTimeChange = (newTime: number) => {
        props.onChange(new Date(newTime).valueOf());
    }
    const time = new Date(props.value);
    time.setSeconds(0, 0);
    return (
        <DateTimePicker
            value={time.toISOString()}
            onChange={(event) => {event && handleTimeChange(event.valueOf())}}
        />
    )
}
