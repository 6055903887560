// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
    PlasmicJobsView,
    DefaultJobsViewProps,
} from "./plasmic/ibeam_access/PlasmicJobsView";
import {HTMLElementRefOf} from "@plasmicapp/react-web";
import JobArtefacts from "./custom/MuiJobArtefacts";
import Map from "./custom/Map";
import AccountMenu from "./custom/AccountMenu";
import {CustomerData, JobDatabaseClient, Location} from "../model/job-database-client";
import {useEffect} from "react";
import {User} from "../model/user";
import GoogleMaps from "../model/google-maps"
import {StorageClient} from "../model/storage-client";
import LocationEditor from "./custom/LocationEditor";
import DateTimeEditor from "./custom/DateTimeEditor";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface JobsViewProps extends Omit<DefaultJobsViewProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultJobsViewProps altogether and have
// total control over the props for your component.
interface JobsViewProps extends DefaultJobsViewProps {
    match: any;
}

function JobsView_(props: JobsViewProps, ref: HTMLElementRefOf<"div">) {
    // Use PlasmicJobsView to render this component as it was
    // designed in Plasmic, by activating the appropriate variants,
    // attaching the appropriate event handlers, etc.  You
    // can also install whatever React hooks you need here to manage state or
    // fetch data.
    //
    // Props you can pass into PlasmicJobsView are:
    // 1. Variants you want to activate,
    // 2. Contents for slots you want to fill,
    // 3. Overrides for any named node in the component to attach behavior and data,
    // 4. Props to set on the root node.
    //
    // By default, we are just piping all JobsViewProps here, but feel free
    // to do whatever works for you.
    const history = useHistory();
    const [customerData, setCustomerData] = React.useState<CustomerData | undefined>(undefined);
    const [storageClient, setStorageClient] = React.useState<StorageClient | undefined>(undefined);
    const [ddbClient, setDdbClient] = React.useState<JobDatabaseClient | undefined>(undefined)
    const [googleMapsApi, setGoogleMapsApi] = React.useState<GoogleMaps | undefined>(undefined);
    const [displayDeleteDialog, setDisplayDeleteDialog] = React.useState<boolean>(false);
    const [deleting, setDeleting] = React.useState<boolean>(false);
    const [username, setUsername] = React.useState<string>("");
    useEffect(() => {
        User.currentUser.username
            .then((username) => {
                setUsername(username);
            });
    }, []);


    useEffect(() => {
        // Get the user credentials and then instantiate the database client.
        User.currentUser.credentials
            .then(async (creds) => {
                // Instantiate the database client and then load data!
                const ddbClient = new JobDatabaseClient({region: 'ap-southeast-2', credentials: creds})
                const customerID = await User.currentUser.userGroup;
                const customerDataReceived = await ddbClient.get(customerID.replace("-ibeam", ""));
                setDdbClient(ddbClient);
                const sClient = new StorageClient({region: 'ap-southeast-2', credentials: creds})
                setStorageClient(sClient);
                GoogleMaps.getInstance(sClient).then(setGoogleMapsApi);
                setCustomerData(customerDataReceived);
            });
    }, []);

    const updateLocation = (newLocation: Location) => {
        const customerDataUpdated = Object.assign({}, customerData);
        customerDataUpdated.jobs[props.match.params.jobNumber].location = newLocation;

        setCustomerData(customerDataUpdated);
        if (ddbClient) {
            ddbClient.put(customerDataUpdated);
        }
    }

    const updateTime = async (newTime: number) => {
        const customerDataUpdated = Object.assign({}, customerData);
        customerDataUpdated.jobs[props.match.params.jobNumber].time = newTime;

        setCustomerData(customerDataUpdated);
        if (ddbClient) {
            await ddbClient.put(customerDataUpdated);
        }
    }

    const deleteJob = async () => {
        const customerDataUpdated = Object.assign({}, customerData);
        setDeleting(true);
        if (customerData) {
            const jobData = Object.assign({}, customerData.jobs[props.match.params.jobNumber]);

            // Remove the job from the database
            delete customerDataUpdated.jobs[props.match.params.jobNumber];
            if (ddbClient && storageClient) {
                try
                {
                    const dbUpdatePromise = ddbClient.put(customerDataUpdated);
                    const storageUpdatePromises: Array<Promise<void>> = jobData.artifacts.map((a) => {
                        return storageClient.delete(a.fileKey, a.bucket);
                    });

                    await Promise.all([dbUpdatePromise, storageUpdatePromises]);
                }
                catch (e) {
                    console.error(`Failed to delete job ${jobData.jobNumber}`);
                    console.error(e);
                }
            }
        }

        setDeleting(false);
        setDisplayDeleteDialog(false);
        history.push('/jobs');
    }

    const isAdmin = username.includes("-admin") || username === "testing";
    const deleteButton=
        <div>
            <IconButton>
                <DeleteForeverIcon fontSize="large" onClick={() => {setDisplayDeleteDialog(true)}}/>
            </IconButton>
            <Dialog
                open={displayDeleteDialog}
                onClose={() => {setDisplayDeleteDialog(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Job?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will permanently delete the job and all associated artifacts. This operation is not reversible.
                    </DialogContentText>
                </DialogContent>
                {!deleting ? (
                    <DialogActions>
                        <Button onClick={() => {setDisplayDeleteDialog(false)}} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={deleteJob} color="primary" autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                ) : <DialogContent>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress></CircularProgress>
                    </div>
                </DialogContent>}
            </Dialog>
        </div>;

    if(customerData && storageClient && googleMapsApi && props.match.params.jobNumber in customerData.jobs) {
        const jobData = customerData.jobs[props.match.params.jobNumber];
        const timeEditableLabel = <DateTimeEditor value={jobData.time} onChange={updateTime}/>;
        const locationEditableLabel = <LocationEditor googleMapsApi={googleMapsApi} location={jobData.location} onChange={updateLocation}/>;
        return (
            <PlasmicJobsView
                root={{ref}}
                avatarIcon={<AccountMenu/>}
                jobNumber={`Job ${jobData.jobNumber}`}
                jobDeleteButton={isAdmin ? deleteButton : null}
                time={timeEditableLabel}
                location={locationEditableLabel}
                map={<Map googleMapsApi={googleMapsApi} position={jobData.location && jobData.location.coordinates ? jobData.location.coordinates : undefined}/>}
                artefacts={<JobArtefacts artifacts={jobData.artifacts} storageClient={storageClient}/>}
            />
        );
    }
    else {
        return (
            <PlasmicJobsView
                root={{ref}}
                avatarIcon={<AccountMenu/>}
                jobDeleteButton={isAdmin ? deleteButton : null}
                loading={true}
            />
        );
    }
}

const JobsView = React.forwardRef(JobsView_);
export default JobsView;
