import {getSignedUrl} from "@aws-sdk/s3-request-presigner"
import {S3Client, GetObjectCommand, DeleteObjectCommand} from "@aws-sdk/client-s3"
import {Credentials} from "@aws-sdk/types";

export interface StorageClientOpts {
    region: string;
    credentials: Credentials;
}

export class StorageClient {
    private s3Client: S3Client;
    constructor(opts: StorageClientOpts) {
        this.s3Client = new S3Client(opts);
    }

    async urlFor(fileKey: string, bucket: string): Promise<string> {
        return await getSignedUrl(this.s3Client, new GetObjectCommand({
            Bucket: bucket,
            Key: fileKey
        }))
    }

    async download(fileKey: string, bucket: string): Promise<void> {
        const url = await this.urlFor(fileKey, bucket);
        return new Promise((resolve) => {
            // Create a temporary HTML element.
            let fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.setAttribute('download', fileKey);
            fileLink.setAttribute('id', fileKey);
            document.body.appendChild(fileLink);

            // when the click is registered, remove the temporary
            // element and resolve the promise.
            fileLink.addEventListener(
                'click',
                () => {
                    document.body.removeChild(fileLink);
                    resolve();
                },
                false,
            );
            fileLink.click();
        });
    }

    async delete(fileKey: string, bucket: string): Promise<void>
    {
        await this.s3Client.send(new DeleteObjectCommand({
            Bucket: bucket,
            Key: fileKey
        }));
    }
}