// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
    PlasmicJobItem,
    DefaultJobItemProps
} from "./plasmic/ibeam_access/PlasmicJobItem";
import {HTMLElementRefOf} from "@plasmicapp/react-web";
import {Job} from '../model/job-database-client';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface JobItemProps extends Omit<DefaultJobItemProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultJobItemProps altogether and have
// total control over the props for your component.
interface JobItemProps extends DefaultJobItemProps {
    job: Job;
    onClick?: () => void;
}

function JobItem_(props: JobItemProps, ref: HTMLElementRefOf<"div">) {
    // Use PlasmicJobItem to render this component as it was
    // designed in Plasmic, by activating the appropriate variants,
    // attaching the appropriate event handlers, etc.  You
    // can also install whatever React hooks you need here to manage state or
    // fetch data.
    //
    // Props you can pass into PlasmicJobItem are:
    // 1. Variants you want to activate,
    // 2. Contents for slots you want to fill,
    // 3. Overrides for any named node in the component to attach behavior and data,
    // 4. Props to set on the root node.
    //
    // By default, we are just piping all JobItemProps here, but feel free
    // to do whatever works for you.
    const getLastUpdateTime = (job: Job): number => {
        let lastUpdateTime = job.time;
        for (const artifact of job.artifacts) {
            if (artifact.time >= lastUpdateTime) {
                lastUpdateTime = artifact.time;
            }
        }
        return lastUpdateTime;
    }
    const date = new Date(getLastUpdateTime(props.job));
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const formattedYear = date.getFullYear();
    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
    const formattedTime = date.toLocaleTimeString('default', {hour12: true, hour: '2-digit', minute: '2-digit'})
    const formattedStreet = props.job.location ? props.job.location.street : "No Location Provided"
    const formattedSuburb = props.job.location && props.job.location.city ? props.job.location.city : "";
    return <PlasmicJobItem rootBox={{props: {onClick: props.onClick}}}
                           jobNumber={props.job.jobNumber}
                           year={formattedYear}
                           date={formattedDate}
                           time={formattedTime}
                           street={formattedStreet}
                           suburb={formattedSuburb}
    />;
}

const JobItem = React.forwardRef(JobItem_);
export default JobItem;
