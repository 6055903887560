import * as React from 'react';
import { registerComponent, PlasmicCanvasHost } from '@plasmicapp/host';
import TextField from '@material-ui/core/TextField';
import CircularProgress from "@material-ui/core/CircularProgress";

// A very simple component that takes two props: a string caption, and a
// children prop that takes any ReactNode.

registerComponent(TextField, {
  name: 'TextField',
  displayName: 'Material UI Text Field',
  props: {
    autoComplete: 'boolean',
    autoFocus: 'boolean',
    defaultValue: 'string',
    label: 'string',
  },
  isDefaultExport: true,
  importPath: '@material-ui/core/TextField'
});

registerComponent(CircularProgress, {
  name: 'CircularProgress',
  displayName: 'Material UI Circular Progress',
  isDefaultExport: true,
  importPath: '@material-ui/core/CircularProgress',
  props: {
    size: "number"
  }
})

export default function HostPage() {
  return (
    <>
      <PlasmicCanvasHost />
    </>
  );
}