// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
    PlasmicJobs,
    DefaultJobsProps
} from "./plasmic/ibeam_access/PlasmicJobs";
import {HTMLElementRefOf} from "@plasmicapp/react-web";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

import {CustomerData, Job, JobDatabaseClient} from "../model/job-database-client"
import JobItem from './JobItem'
import {User} from "../model/user";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import AccountMenu from "./custom/AccountMenu";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface JobsProps extends Omit<DefaultJobsProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultJobsProps altogether and have
// total control over the props for your component.
interface JobsProps extends DefaultJobsProps {
}

function partialMatches(job: Job, searchStr: string) {
    const partialStrMatch = (str1: string, str2: string) => str1.toLowerCase().includes(str2.toLowerCase());
    return searchStr.length === 0 ||
        partialStrMatch(job.jobNumber, searchStr) ||
        (job.location && partialStrMatch(job.location.street, searchStr));
}

function Jobs_(props: JobsProps, ref: HTMLElementRefOf<"div">) {
    // Use PlasmicJobs to render this component as it was
    // designed in Plasmic, by activating the appropriate variants,
    // attaching the appropriate event handlers, etc.  You
    // can also install whatever React hooks you need here to manage state or
    // fetch data.
    //
    // Props you can pass into PlasmicJobs are:
    // 1. Variants you want to activate,
    // 2. Contents for slots you want to fill,
    // 3. Overrides for any named node in the component to attach behavior and data,
    // 4. Props to set on the root node.
    //
    // By default, we are just piping all JobsProps here, but feel free
    // to do whatever works for you.

    // Proxy for the actual data, this should be loaded on login and passed in as a
    // prop to this component.
    const history = useHistory();
    const [searchQuery, setSearchQuery] = React.useState("");
    // TODO: fix job item layout (make job-number thinner).
    const [customerData, setCustomerData] = React.useState<CustomerData | undefined>(undefined);
    useEffect(() => {
        // Get the user credentials and then instantiate the database client.
        User.currentUser.credentials
            .then(async (creds) => {
              // Instantiate the database client and then load data!
              const ddbClient = new JobDatabaseClient({region: 'ap-southeast-2', credentials: creds})
              const customerID = await User.currentUser.userGroup;
              const customerDataReceived = await ddbClient.get(customerID.replace("-ibeam", ""));
              console.log(customerDataReceived)
              setCustomerData(customerDataReceived);
            });
    }, []);

    const getLastUpdateTime = (job: Job): number => {
        let lastUpdateTime = job.time;
        for (const artifact of job.artifacts) {
            if (artifact.time >= lastUpdateTime) {
                lastUpdateTime = artifact.time;
            }
        }
        return lastUpdateTime;
    }

    const jobItemsToDisplay = customerData ? Object.values(customerData.jobs)
        .filter(job => partialMatches(job, searchQuery))
        .sort((j1, j2) => getLastUpdateTime(j2) - getLastUpdateTime(j1))
        .map(job => <JobItem key={job.jobNumber} job={job} onClick={() => {history.push(`/job-view/${job.jobNumber}`)}}/>) : [];

    const searchBar = <TextField
        label="Search"
        variant="outlined"
        size="small"
        color="secondary"
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <Search/>
                </InputAdornment>
            )
        }}
        onChange={(event) => setSearchQuery(event.target.value)}
    />

    return <PlasmicJobs root={{ref}} searchBar={searchBar} jobs={jobItemsToDisplay} avatarIcon={<AccountMenu/>} {...props} />;
}

const Jobs = React.forwardRef(Jobs_);
export default Jobs;
