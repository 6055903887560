import Amplify, {Auth} from "aws-amplify"
import {ICredentials} from "aws-amplify/lib/Common/types/types";

function configureAmplifyAuth() {
    Amplify.configure({
        Auth: {
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            identityPoolId:
                'ap-southeast-2:efaaaf47-95e1-4e47-b397-2089c54178c3',

            // REQUIRED - Amazon Cognito Region
            region: 'ap-southeast-2',

            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: 'ap-southeast-2_Lwdz88WOB',
            userPoolWebClientId: '4udsc7kkhf3mbadhhqbq9vt7mv',

            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: true,

            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            authenticationFlowType: 'USER_PASSWORD_AUTH',
        },
    })
    Auth.configure();
}


export class User {
    private isSignedIn: boolean;
    private static instance: User = new User();

    constructor() {
        configureAmplifyAuth();
        this.isSignedIn = false;
    }

    static get currentUser() {
        return User.instance;
    }

    async signIn(username: string, password: string) {
        let user = await Auth.signIn(username, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            user = await this.autoReset(user, password);
        }
        if (user.challengeName) {
            throw new Error(user.challengeName);
        }
    }

    get username(): Promise<string> {
        const getUsername = async () => {
            return (await Auth.currentUserInfo()).username;
        }
        return getUsername();
    }

    get credentials(): Promise<ICredentials> {
        return Auth.currentUserCredentials();
    }

    get userGroup(): Promise<string> {
        const getUserGroup = async () => {
            const session = await Auth.currentSession();
            const idTokenPayload = session.getIdToken().decodePayload();
            if(idTokenPayload['cognito:groups'].length > 0) {
                return idTokenPayload['cognito:groups'][0];
            }
            throw new Error('Invalid User');
        }
        return getUserGroup();
    }

    async changePassword(oldPassword: string, newPassword: string) {
        let user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
    }

    private async autoReset(user: any, password: string) {
        user = await Auth.completeNewPassword(
            user, // the Cognito User Object
            password, // the new password
        );
        user = await Auth.signIn(user.username, password);
        return user;
    }

    async signOut() {
        await Auth.signOut({global: true});
    }
}