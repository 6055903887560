import React from "react";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EditIcon from "@material-ui/icons/Edit";
import VideocamIcon from "@material-ui/icons/Videocam";
import AssessmentIcon from "@material-ui/icons/Assessment";
import GridOnIcon from "@material-ui/icons/GridOn";
import {Artifact} from "../../model/job-database-client";
import {StorageClient} from "../../model/storage-client";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
      },
    })
);

export interface MuiJobArtefactsProps {
  storageClient: StorageClient;
  artifacts: Array<Artifact>;
}

export default function MuiJobArtefacts(props: MuiJobArtefactsProps) {
  const classes = useStyles();
  return (
      <List className={classes.root}>
        {props.artifacts.sort((a1, a2) => a2.time - a1.time).map(artifact => createListItemForArtifact(artifact, props.storageClient))}
      </List>
  );
}


function createListItemForArtifact(artifact: Artifact, storageClient: StorageClient) {
  const avatar = {
    report: <AssessmentIcon/>,
    "spiraline-log": <GridOnIcon/>,
    video: <VideocamIcon/>
  }
  const displayName = {
    report: "Report",
    "spiraline-log": "Spiraline Log",
    video: "Video"
  }
  const date = new Date(artifact.time);
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
  const formattedTime = date.toLocaleTimeString('default', {hour12: true, hour: '2-digit', minute: '2-digit'})
  return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            {avatar[artifact.type]}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={displayName[artifact.type]} secondary={`${formattedTime}, ${formattedDate}`}/>
        <ListItemIcon>
          <IconButton>
            <EditIcon/>
          </IconButton>
        </ListItemIcon>
        <ListItemIcon>
          <IconButton>
            <CloudDownloadIcon onClick={() => {storageClient.download(artifact.fileKey, artifact.bucket)}}/>
          </IconButton>
        </ListItemIcon>
      </ListItem>
  );
}
