import {
    PlasmicRootProvider,
    PlasmicComponent,
    ComponentRenderData,
} from "@plasmicapp/loader-react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {useEffect, useState} from "react";
import {PLASMIC} from "./plasmic-init";
import HostPage from "./HostPage";
import LoginPage from "./components/LoginPage";
import Jobs from "./components/Jobs";
import JobsView from "./components/JobsView";
import PasswordUpdatePage from "./components/PasswordUpdatePage"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"

export function AppRoot() {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <PlasmicRootProvider loader={PLASMIC}>
                <Router>
                    <Switch>
                        {/* Your other routes... */}
                        <Route exact path="/" component={LoginPage}/>
                        <Route exact path="/jobs" component={Jobs}/>
                        <Route exact path="/job-view/:jobNumber" component={JobsView}/>
                        <Route exact path="/profile" component={PasswordUpdatePage}/>
                        <Route
                            exact
                            path={"/__host__"}
                            render={({match}) => <HostPage/>}
                        />
                        <Route component={CatchAllPage}/>
                    </Switch>
                </Router>
            </PlasmicRootProvider>
        </MuiPickersUtilsProvider>
    );
}

// We try loading the Plasmic page for the current route.
// If it doesn't exist, then return "Not found."
export function CatchAllPage() {
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState<ComponentRenderData | null>(null);

    useEffect(() => {
        async function load() {
            console.log(window.location.pathname);
            const pageData = await PLASMIC.maybeFetchComponentData(
                window.location.pathname
            );
            setPageData(pageData);
            setLoading(false);
        }

        load();
    }, []);

    if (loading) {
        return null; //'Loading...';
    }
    if (!pageData) {
        return null; //'Not found';
    }
    // The page will already be cached from the `load` call above.
    return <PlasmicComponent component={window.location.pathname}/>;
}
