import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {Location} from '../../model/job-database-client'
import GoogleMaps from '../../model/google-maps';

const autocompleteService = {current: null};

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

interface PlaceType {
    description: string;
    place_id?: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}

interface LocationEditorProps {
    location?: Location;
    googleMapsApi: GoogleMaps;
    onChange: (newLocation: Location) => void;
}

export default function LocationEditor(props: LocationEditorProps) {
    // TODO: style text white.
    const classes = useStyles();
    const [value, setValue] = React.useState<PlaceType | null>({
        description: props.location ? (props.location.street + (props.location.city ?  props.location.city : '')) : '',
        structured_formatting: {
            main_text: '',
            secondary_text: '',
            main_text_matched_substrings: [{offset: 0, length:0}]
        }
    });
    const [inputValue, setInputValue] = React.useState<string>('');
    const [options, setOptions] = React.useState<PlaceType[]>([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                (autocompleteService.current as any).getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({input: inputValue}, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="google-map-demo"
            style={{width: 300}}
            getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x: any) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                if(newValue && newValue.place_id) {
                    try {
                        props.googleMapsApi.getLocation(newValue.place_id)
                            .then((location) => {props.onChange(location)})
                    }
                    catch (err) {
                        // TODO: handle error properly.
                    }
                }
            }}
            onInputChange={(event: any, newInputValue: any) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params: any) => (
                <TextField {...params} label="Add a location" variant="standard" color="secondary" fullWidth/>
            )}
            renderOption={(option: any) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon className={classes.icon}/>
                        </Grid>
                        <Grid item xs>
                            {parts.map((part: any, index: any) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                  {part.text}
                                </span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}
