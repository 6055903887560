// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicPasswordUpdatePage,
  DefaultPasswordUpdatePageProps
} from "./plasmic/ibeam_access/PlasmicPasswordUpdatePage";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import TextField from "@material-ui/core/TextField";
import {useState} from "react";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {User} from "../model/user";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface PasswordUpdatePageProps extends Omit<DefaultPasswordUpdatePageProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultPasswordUpdatePageProps altogether and have
// total control over the props for your component.
interface PasswordUpdatePageProps extends DefaultPasswordUpdatePageProps {}

function PasswordUpdatePage_(
  props: PasswordUpdatePageProps,
  ref: HTMLElementRefOf<"div">
) {
  // Use PlasmicPasswordUpdatePage to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicPasswordUpdatePage are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all PasswordUpdatePageProps here, but feel free
  // to do whatever works for you.
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");

  const currentPasswordTextField = <TextField
      label="Current Password"
      variant="outlined"
      type="password"
      value={currentPassword}
      onChange={(event) => {setCurrentPassword(event.target.value)}}
      error={(!!error)}
  />;
  const newPasswordTextField = <TextField
      label="New Password"
      variant="outlined"
      type="password"
      value={newPassword}
      onChange={(event) => {setNewPassword(event.target.value)}}
      error={(!!error)}
  />;
  const confirmNewPasswordTextField = <TextField
      label="Confirm New Password"
      variant="outlined"
      type="password"
      value={confirmNewPassword}
      onChange={(event) => {setConfirmNewPassword(event.target.value)}}
      error={(!!error)}
      helperText={error ? error : undefined}
  />;

  const returnToJobs = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    history.push('/jobs')
  }

  const handleUpdate = async () => {
    if(newPassword !== confirmNewPassword) {
      setError("New password is not consistent");
    }
    else {
      try {
        const user = User.currentUser;
        await user.changePassword(currentPassword, newPassword);
        returnToJobs();
      }
      catch (err){
        setError("Password is invalid")
      }
    }
  }

  const updateButton = <Button variant="contained" color={"primary"} onClick={handleUpdate}>Update</Button>;
  const cancelButton = <Button color={"secondary"} onClick={returnToJobs}>Cancel</Button>;
  return <PlasmicPasswordUpdatePage
      root={{ ref }}
      currentPassword={currentPasswordTextField}
      newPassword={newPasswordTextField}
      confirmNewPassword={confirmNewPasswordTextField}
      updateButton={updateButton}
      cancelButton={cancelButton}
      {...props} />;
}

const PasswordUpdatePage = React.forwardRef(PasswordUpdatePage_);
export default PasswordUpdatePage;
