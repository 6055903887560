import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#82C341',
    },
    secondary: {
      main: '#939598',
    },
  },
});