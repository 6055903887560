import {AttributeValue, DynamoDBClient, GetItemCommand, PutItemCommand} from "@aws-sdk/client-dynamodb"
import {Credentials} from "@aws-sdk/types";

export interface CustomerData {
    customerID: string;
    jobs: {[jobNumber: string] : Job};
    unfiledArtifacts: Array<Artifact>;
}

export interface Job {
    jobNumber: string;
    time: number;
    lastUpdateTime: number;
    location?: Location;
    artifacts: Array<Artifact>;
}

export interface Location {
    street: string;
    city?: string;
    state?: string;
    country?: string;
    coordinates?: Coordinates;
}

export interface Coordinates {
    lat: number;
    lng: number;
}

export interface Artifact {
    type: JobArtifactType;
    time: number;
    fileKey: string;
    bucket: string;
    unitID: string;
}

export type JobArtifactType = "video" | "spiraline-log" | "report";

export interface DatabaseClientOpts {
    region: string;
    credentials: Credentials;
}

export class JobDatabaseClient {
    private readonly ddbClient: DynamoDBClient;

    constructor(opts: DatabaseClientOpts) {
        this.ddbClient = new DynamoDBClient({
            region: opts.region,
            credentials: opts.credentials
        });
    }

    async get(customerID: string): Promise<CustomerData> {
        const response = await this.ddbClient.send(new GetItemCommand({
            TableName: "ibeam-jobs",
            Key: {"customer-id": {S: customerID}}
        }))

        // Format the Data as per the customer Data
        if (response.Item) {
            return {
                customerID: (response.Item['customer-id'].S as string),
                jobs: this.parseJobs(response.Item['jobs']),
                unfiledArtifacts: this.parseArtifacts(response.Item['unfiled-artifacts'])
            }
        }
        else {
            return {
                customerID: customerID,
                jobs: {},
                unfiledArtifacts: []
            }
        }
    }

    private parseJobs(jobsAttr: AttributeValue): {[jobNumber: string] : Job} {
        let jobs: {[jobNumber: string] : Job} = {};
        if(jobsAttr.M) {
            for(const [jobNumber, jobAttr] of Object.entries(jobsAttr.M)) {
                const jobAttrMap: {[p: string]: AttributeValue} | undefined = jobAttr.M;
                if(jobAttrMap) {
                    jobs[jobNumber] = {
                        jobNumber: (jobAttrMap['job-number'].S as string),
                        time: Number(jobAttrMap['time'].N as string),
                        lastUpdateTime: Number(jobAttrMap['last-update-time'].N as string),
                        location: jobAttrMap['location'] ?
                            JobDatabaseClient.parseLocation(jobAttrMap['location']) : undefined,
                        artifacts: this.parseArtifacts(jobAttrMap['artifacts'])
                    }
                }
            }
        }
        return jobs;
    }

    private static parseLocation(locationAttr: AttributeValue): Location {
        if(locationAttr.M) {
            return {
                street: (locationAttr.M['street'].S as string),
                city: locationAttr.M['city'] ? locationAttr.M['city'].S : undefined,
                state: locationAttr.M['state'] ? locationAttr.M['state'].S : undefined,
                country: locationAttr.M['country'] ? locationAttr.M['country'].S : undefined,
                coordinates: locationAttr.M['coordinates'] && locationAttr.M['coordinates'].M ?
                    {
                        lat: Number(locationAttr.M['coordinates'].M['lat'].N),
                        lng: Number(locationAttr.M['coordinates'].M['lng'].N)
                    } : undefined
            }
        }
        else {
            throw new Error('Invalid location format');
        }
    }

    private parseArtifacts(artifactsAttr: AttributeValue): Array<Artifact> {
        if(artifactsAttr.L) {
            let artifacts: Array<Artifact> = [];
            for (const artifactAttr of artifactsAttr.L) {
                const artifactAttrMap: {[p: string]: AttributeValue} | undefined = artifactAttr.M;
                if(artifactAttrMap) {
                    artifacts.push({
                        type: (artifactAttrMap['type'].S as JobArtifactType),
                        time: Number(artifactAttrMap['time'].N),
                        fileKey: (artifactAttrMap['file-key'].S as string),
                        bucket: (artifactAttrMap['bucket'].S as string),
                        unitID: (artifactAttrMap['unit-id'].S as string),
                    })
                }
            }
            return artifacts;
        }
        else {
            throw new Error('Invalid Artefact list format');
        }
    }

    async put(customerData: CustomerData) {
        // This should simply convert the customer data to the expected format and then issue a Put request.
        const item : {[p: string]: AttributeValue} = {
            'customer-id': {S: customerData.customerID},
            'jobs': this.formatJobs(customerData.jobs),
            'unfiled-artifacts': this.formatArtifacts(customerData.unfiledArtifacts),
        }

        await this.ddbClient.send(new PutItemCommand({
            TableName: "ibeam-jobs",
            Item: item
        }))
    }

    private formatJobs(jobs: {[jobNumber: string] : Job}): AttributeValue {
        const jobsAttr: AttributeValue = {M: {}}
        for(const [jobNumber, job] of Object.entries(jobs)) {
            let jobAttr: AttributeValue = {M: {
                'job-number': {S: job.jobNumber},
                'time': {N: job.time.toString()},
                'last-update-time': {N: job.lastUpdateTime.toString()},
                'artifacts': this.formatArtifacts(job.artifacts)
            }};
            if(job.location) jobAttr.M['location'] = this.formatLocation(job.location);
            jobsAttr.M[jobNumber] = jobAttr;
        }
        return jobsAttr;
    }

    private formatLocation(location: Location): AttributeValue {
        let locationAttr: AttributeValue = {M: {
            'street': {S: location.street}
        }}
        if(location.city) locationAttr.M['city'] = {S: location.city}
        if(location.state) locationAttr.M['state'] = {S: location.state}
        if(location.country) locationAttr.M['country'] = {S: location.country}
        if(location.coordinates) locationAttr.M['coordinates'] = {M: {
            lat : {N: location.coordinates.lat.toString()},
            lng : {N: location.coordinates.lng.toString()}
        }}
        return locationAttr;
    }

    private formatArtifacts(artifacts: Array<Artifact>): AttributeValue {
        const artifactsAttr: AttributeValue = {L: []}
        for(const artifact of artifacts) {
            artifactsAttr.L.push({
                M: {
                    'type': {S: artifact.type},
                    'file-key': {S: artifact.fileKey},
                    'bucket': {S: artifact.bucket},
                    'time': {N: artifact.time.toString()},
                    'unit-id': {S: artifact.unitID}
                }
            })
        }
        return artifactsAttr;
    }
}