import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Typography from '@material-ui/core/Typography';
import GoogleMapsApi from "../../model/google-maps";

interface MapProps {
    position?: {lat: number, lng: number};
    googleMapsApi: GoogleMapsApi
}

export default function Map(props: MapProps) {
    const component = props.position ?
        <GoogleMap defaultZoom={12} defaultCenter={props.position}>
            <Marker position={props.position} />
        </GoogleMap> :
        <Typography>Update location to view on map</Typography>;

    const MyMapComponent = compose(
        withProps({
            googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${props.googleMapsApi.apiKey}&v=3.exp&libraries=geometry,drawing,places`,
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `400px` }} />,
            mapElement: <div id="map" style={{ height: `100%` }} />,
        }),
        withScriptjs,
        withGoogleMap
    )((props: any) => (
        component
    ));
    return <MyMapComponent />;
}
