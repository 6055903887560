// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
    PlasmicLoginPage,
    DefaultLoginPageProps
} from "./plasmic/ibeam_access/PlasmicLoginPage";
import {HTMLElementRefOf} from "@plasmicapp/react-web";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {User} from "../model/user";
import {useState} from "react";
import {useHistory} from "react-router-dom";
// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface LoginPageProps extends Omit<DefaultLoginPageProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultLoginPageProps altogether and have
// total control over the props for your component.
interface LoginPageProps extends DefaultLoginPageProps {
}

function LoginPage_(props: LoginPageProps, ref: HTMLElementRefOf<"div">) {
    // Use PlasmicLoginPage to render this component as it was
    // designed in Plasmic, by activating the appropriate variants,
    // attaching the appropriate event handlers, etc.  You
    // can also install whatever React hooks you need here to manage state or
    // fetch data.
    //
    // Props you can pass into PlasmicLoginPage are:
    // 1. Variants you want to activate,
    // 2. Contents for slots you want to fill,
    // 3. Overrides for any named node in the component to attach behavior and data,
    // 4. Props to set on the root node.
    //
    // By default, we are just piping all LoginPageProps here, but feel free
    // to do whatever works for you.
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const handleSignIn = async () => {
        try {
            setLoading(true);
            const user = User.currentUser;
            await user.signIn(username, password);
            setUsername("");
            setPassword("");
            setError(null);
            setLoading(false);
            history.push('/jobs');
        }
        catch(err) {
            console.log(err);

            // Set the error!
            setError(err);
            setLoading(false);
        }
    }

    const userNameTextField = <TextField
        label="Username"
        variant="outlined"
        size="small"
        value={username}
        onChange={(event) => {setUsername(event.target.value)}}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <AccountCircle/>
                </InputAdornment>
            ),
        }}
        error={(!!error)}
        helperText={error ? "Invalid Username / Password" : undefined}
    />;
    const passwordTextField = <TextField
        label="Password"
        variant="outlined"
        size="small"
        type="password"
        value={password}
        onChange={(event) => {setPassword(event.target.value)}}
        error={(!!error)}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <LockOpenIcon/>
                </InputAdornment>
            ),
        }}
    />;
    const signInButton = <Button style={{textTransform: 'none'}} onClick={handleSignIn}>
        Sign In
    </Button>
    return <PlasmicLoginPage
        root={{ref}}
        usernameTextField={userNameTextField}
        passwordTextField={passwordTextField}
        signInButton={signInButton}
        loading={loading}
        />;
}

const LoginPage = React.forwardRef(LoginPage_);
export default LoginPage;
