import axios from "axios"
import {Location} from "./job-database-client";
import {StorageClient} from "./storage-client";

export default class GoogleMaps {
    private static instance: GoogleMaps | undefined;
    readonly apiKey: string;
    private placesService: any;

    constructor(apiKey: string) {
        this.apiKey = apiKey;
    }

    async getLocation(placeId: string): Promise<Location> {
        let placesService = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
        return new Promise((resolve, reject) => {
            placesService.getDetails({placeId: placeId}, (result: any, status: any) => {
                if (result) {
                    const streetName: string | undefined = findComponent(result.address_components, 'route');
                    if(streetName) {
                        resolve({
                            street: streetName,
                            city: findComponent(result.address_components, 'locality'),
                            state: findComponent(result.address_components, 'administrative_area_level_1'),
                            country: findComponent(result.address_components, 'country'),
                            coordinates: {
                                lat: result.geometry.location.lat(),
                                lng: result.geometry.location.lng(),
                            }
                        })
                    }
                    else {
                        reject('No streetname defined for place')
                    }
                }
            })
        })
    }

    static async getInstance(storageClient: StorageClient): Promise<GoogleMaps> {
        if(!GoogleMaps.instance) {
            const secureParametersUrl = await storageClient.urlFor(
                'ibtech-cloud-parameters.json',
                'ibtech-cloud-private-bucket');
            const secureParameters = await axios.get(secureParametersUrl);
            const apiKey = secureParameters.data.googleMapsApiKey;
            GoogleMaps.instance = new GoogleMaps(apiKey);
        }
        return GoogleMaps.instance;
    }
}

interface AddressComponent {
    long_name: string,
    types: Array<string>,
}

function findComponent(addressComponents: Array<AddressComponent>, type: string): string | undefined {
    const component : AddressComponent | undefined = addressComponents
        .find((element) => element.types.find((t) => t === type));
    if(component) {
        return component.long_name;
    }
}