// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicJobArtefact,
  DefaultJobArtefactProps,
} from "./plasmic/ibeam_access/PlasmicJobArtefact";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Grid from "@material-ui/core/Grid";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface JobArtefactProps extends Omit<DefaultJobArtefactProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultJobArtefactProps altogether and have
// total control over the props for your component.
interface JobArtefactProps extends DefaultJobArtefactProps {}

function JobArtefact_(props: JobArtefactProps, ref: HTMLElementRefOf<"div">) {
  // Use PlasmicJobArtefact to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicJobArtefact are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all JobArtefactProps here, but feel free
  // to do whatever works for you.
  const action = (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item>
        <IconButton color="primary">
          <CloudDownloadIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  return <PlasmicJobArtefact root={{ ref }} actions={action} {...props} />;
}

const JobArtefact = React.forwardRef(JobArtefact_);
export default JobArtefact;
