import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockIcon from '@material-ui/icons/Lock';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
import {Hidden, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import {User} from "../../model/user";


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    // @ts-ignore
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export default function AccountMenu() {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [username, setUsername] = React.useState<string>("");
    useEffect(() => {
        User.currentUser.username
            .then((username) => {
                setUsername(username);
            });
    }, []);
    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        User.currentUser.signOut();
        history.push("/");
    }

    const handleUpdatePassword = () => {
        history.push("/profile");
    }

    return (
        <div>
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="primary"
                onClick={handleMenuClick}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <AccountCircle />
                    </Grid>
                    <Grid item>
                        <Hidden smDown>
                            <Typography>{username}</Typography>
                        </Hidden>
                    </Grid>
                </Grid>
            </IconButton>

            <StyledMenu
                // @ts-ignore
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleUpdatePassword}>
                    <ListItemIcon>
                        <LockIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                </MenuItem>
            </StyledMenu>
        </div>
    );
}
